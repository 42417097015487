import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  Button,
  CircularProgress,
  Container,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Error as ErrorIcon,
  ExitToApp as ExitToAppIcon,
  FilterNone,
  MailOutline as MailOutlineIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { withRouter, Redirect } from 'react-router-dom';
import { withDialog } from 'muibox';
import firebase from './firebaseConfig';
import AuthLogo from './assets/auth-logo.svg';

class NoDataError extends Error {
  constructor(code, message) {
    super(code, message);
    this.code = code;
    this.message = message;
    this.name = code;
  }
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  colore: {
    color: `#ffffff`,
  },
  customInput: {
    borderRadius: theme.spacing(0.75),
    color: theme.palette.grey[400],
    font: `normal 14px/20px Hind`,
    letterSpacing: 0,
    minHeight: theme.spacing(2.875),
    textAlign: `left`,
    '& > div > fieldset': {
      border: `none`,
    },
  },
  customInputChild: {
    background: `#F5F5F5 0% 0% no-repeat padding-box`,
  },
  customInputError: {
    border: `2px solid ${theme.palette.secondary[theme.palette.type]}`,
    borderRadius: theme.spacing(0.75),
  },
  customInputHelperText: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(
      1,
    )}px`,
  },
  customInputInput: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.25)}px`,
  },
  customInputWrapperError: {
    background: `${theme.palette.secondary[theme.palette.type]}1a 0% 0% no-repeat padding-box`,
  },
  form: {
    width: `100%`, // Fix IE 11 issue.
  },
  grid: {
    cursor: `pointer`,
    marginTop: 10,
  },
  loginTypography: {
    color: theme.palette.primary.dark,
    font: `600 18px/25px Hind`,
    letterSpacing: -0.3,
    margin: `${theme.spacing(6.25)}px 0 ${theme.spacing(1.5)}px 0`,
    textAlign: `left`,
  },
  main: {
    alignContent: `center`,
    alignItems: `center`,
    display: `flex`,
    justifyContent: `center`,
  },
  paper: {
    alignContent: `flex-start`,
    alignItems: `flex-start`,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    maxWidth: 540,
    [theme.breakpoints.up(`sm`)]: {
      minWidth: 540,
      padding: `${theme.spacing(9)}px ${theme.spacing(6)}px`,
    },
    [theme.breakpoints.down(`xs`)]: {
      minWidth: `100%`,
      padding: `${theme.spacing(9)}px ${theme.spacing(3)}px`,
    },
  },
  resetPassword: {
    marginTop: theme.spacing(1.25),
  },
  resetPasswordLink: {
    cursor: `pointer`,
    font: `600 14px/20px Hind`,
    letterSpacing: 0,
    textAlign: `left`,
  },
  resetTypography: {
    margin: `${theme.spacing(6.25)}px 0 ${theme.spacing(2.5)}px 0`,
  },
  resetTypographyHelper: {
    color: `#222222`,
    font: `500 14px/20px Hind`,
    letterSpacing: 0,
    textAlign: `left`,
  },
  submit: {
    font: `600 14px/20px Hind`,
    letterSpacing: 0,
    lineHeight: `${theme.spacing(2.875)}px`,
    marginTop: theme.spacing(2),
    textAlign: `left`,
    textTransform: `none`,
    '& > span > svg': {
      marginRight: theme.spacing(1.25),
    },
  },
  success: {
    backgroundColor: green[600],
  },
}));

function SignIn({ dialog, history }) {
  const [values, setValues] = useState({
    email: ``,
    password: ``,
    isSaving: false,
    error: ``,
    resetemail: ``,
  });
  const [reset, setReset] = useState({ isReseting: false, isReseted: false, showSnack: false });
  const [redirectToHome, setRedirectToHome] = useState(null);
  const [isWaitingFirebase, setIsWaitingFirebase] = useState(true);
  const [snackbarStatus, setSnackbarStatus] = useState(false);
  const classes = useStyles();

  const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
    if (currentUser) {
      const db = firebase.firestore();
      const userRef = db.collection(`users`).doc(currentUser.uid);
      const user = await userRef.get();
      const userData = user.data();
      if (user.exists && userData.forceChangePassword) {
        setIsWaitingFirebase(false);
      } else {
        checkCurrentOrganization(userData, userRef);
      }
    } else {
      setIsWaitingFirebase(false);
    }
  });

  const checkCurrentOrganization = async (userData, userRef) => {
    const { currentOrganization, organizations } = userData;
    if (organizations && organizations.length) {
      if (!currentOrganization) {
        const firstOrganization = await firebase
          .firestore()
          .collection(`organizations`)
          .doc(organizations[0])
          .get();
        const firstOrganizationData = { id: firstOrganization.id, ...firstOrganization.data() };
        await userRef.update({ currentOrganization: firstOrganizationData });
        window.localStorage.setItem(`currentOrganization`, JSON.stringify(firstOrganizationData));
      } else {
        window.localStorage.setItem(`currentOrganization`, JSON.stringify(currentOrganization));
      }
      doRedirect();
    } else {
      authAlert();
      unsubscribe && unsubscribe();
      firebase.auth().signOut();
    }
  };

  const authAlert = (message) => {
    dialog.alert({
      title: `Entre em contato com nosso suporte`,
      message: (
        <>
          <Typography>
            {message ||
              `Você não possui nenhuma organização cadastrada em seu usuário, por favor contate nosso
              suporte através do e-mail:`}
          </Typography>
          <Typography align="center" color="primary">
            vagner.nicolodi@medvia.com.br{` `}
            <IconButton
              onClick={() => {
                setSnackbarStatus(true);
                navigator.clipboard.writeText(`vagner.nicolodi@medvia.com.br`);
              }}
            >
              <FilterNone />
            </IconButton>
          </Typography>
        </>
      ),
      ok: { text: `Ok`, color: `primary` },
    });
  };

  async function signInWithFirebase(event) {
    event.preventDefault();
    setValues({ ...values, isSaving: true });
    const { email, password } = values;
    try {
      const user = await firebase.auth().signInWithEmailAndPassword(email, password);
      setValues({ ...values, isSaving: false, error: `` });
      const db = firebase.firestore();
      const firestoreUserRef = db.collection(`users`).doc(user.user.uid);
      const firestoreUser = await firestoreUserRef.get();
      const firestoreUserData = firestoreUser.data();
      const { exists } = firestoreUser;
      if (!exists)
        throw new NoDataError(
          `auth/no-user-data`,
          `Existe algum problema com o seu usário, favor contatar o nosso suporte:`,
        );
      const { forceChangePassword } = firestoreUserData;
      if (forceChangePassword) {
        await checkCurrentOrganization(firestoreUserData, firestoreUserRef);
        history.replace(`/changepassword`);
      } else {
        checkCurrentOrganization(firestoreUserData, firestoreUserRef);
      }
    } catch (error) {
      let customMessage = ``;
      const { code, message } = error;
      switch (code) {
        case `auth/user-not-found`:
          customMessage = `Usuário não existe ou foi deletado.`;
          break;
        case `auth/wrong-password`:
          customMessage = `Senha incorreta.`;
          break;
        case `auth/network-request-failed`:
          customMessage = `Sem internet ou conexão ruim.`;
          break;
        case `auth/no-user-data`:
          firebase.auth().signOut();
          authAlert(message);
          break;
        default:
          customMessage = message;
      }
      setValues({ ...values, isSaving: false, error: customMessage });
    }
  }

  function doRedirect() {
    const parameters = new URLSearchParams(window.location.search);
    if (parameters.get(`redirect`)) {
      window.location.assign(parameters.get(`redirect`));
      setRedirectToHome(false);
    } else {
      setRedirectToHome(true);
    }
  }

  async function resetWithFirebase(event) {
    event.preventDefault();
    firebase
      .auth()
      .sendPasswordResetEmail(values.resetemail)
      .then(() => {
        setValues({ ...values, showSnack: true });
        setTimeout(() => {
          setValues({ ...values, isReseted: true, showSnack: false });
        }, 2000);
      })
      .catch((error) => {
        let customMessage = ``;
        const { code, message } = error;
        switch (code) {
          case `auth/user-not-found`:
            customMessage = `Esse e-mail não existe em nosso banco de dados.`;
            break;
          default:
            customMessage = message;
        }
        setValues({ ...values, isSaving: false, error: customMessage });
      });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <Container className={classes.main} component="main">
      <Paper className={classes.paper}>
        <AuthLogo viewBox="7 15 151 29.70" />
        {!reset.isReseting ? (
          <span>
            <Typography className={classes.loginTypography}>Acesse sua conta</Typography>
            {!isWaitingFirebase ? (
              <form className={classes.form} noValidate onSubmit={signInWithFirebase}>
                <TextField
                  autoComplete="email"
                  autoFocus
                  classes={{
                    root: values.error ? classes.customInputWrapperError : undefined,
                  }}
                  className={classes.customInput}
                  error={!!values.error}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.customInputHelperText,
                    },
                  }}
                  fullWidth
                  id="email"
                  InputProps={{
                    classes: {
                      error: classes.customInputError,
                      root: classes.customInputChild,
                    },
                    endAdornment: values.error ? (
                      <InputAdornment position="end">
                        <ErrorIcon color="secondary" fontSize="small" />
                      </InputAdornment>
                    ) : undefined,
                    inputProps: {
                      classes: {
                        root: classes.customInputInput,
                      },
                    },
                  }}
                  margin="dense"
                  name="email"
                  onChange={handleInputChange}
                  placeholder="Usuário"
                  required
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  autoComplete="current-password"
                  classes={{
                    root: values.error ? classes.customInputWrapperError : undefined,
                  }}
                  className={classes.customInput}
                  error={!!values.error}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.customInputHelperText,
                    },
                  }}
                  fullWidth
                  helperText={!!values.error && values.error}
                  id="password"
                  InputProps={{
                    classes: {
                      error: classes.customInputError,
                      root: classes.customInputChild,
                    },
                    endAdornment: values.error ? (
                      <InputAdornment position="end">
                        <ErrorIcon color="secondary" fontSize="small" />
                      </InputAdornment>
                    ) : undefined,
                    inputProps: {
                      classes: {
                        root: classes.customInputInput,
                      },
                    },
                  }}
                  margin="dense"
                  name="password"
                  onChange={handleInputChange}
                  placeholder="Senha"
                  required
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Button
                  className={classes.submit}
                  color="primary"
                  disabled={values.isSaving}
                  fullWidth
                  type="submit"
                  variant="contained"
                >
                  <ExitToAppIcon /> Entrar
                </Button>
                {values.isSaving && <LinearProgress />}
                <Grid className={classes.resetPassword} item>
                  <Link
                    className={classes.resetPasswordLink}
                    onClick={() => {
                      setReset({ ...reset, isReseting: true });
                      setValues((prevValues) => ({ ...prevValues, error: `` }));
                    }}
                    variant="body2"
                  >
                    Não consegue entrar?
                  </Link>
                </Grid>
              </form>
            ) : (
              <Grid
                alignContent="center"
                alignItems="center"
                container
                justify="center"
                width={273}
              >
                <CircularProgress
                  style={{
                    width: 100,
                    height: 100,
                  }}
                />
              </Grid>
            )}
          </span>
        ) : (
          <span>
            <Typography className={`${classes.loginTypography} ${classes.resetTypography}`}>
              Não consegue entrar?
            </Typography>
            <Typography className={classes.resetTypographyHelper}>
              Informe seu e-mail abaixo para receber um link de recuperação
            </Typography>
            <form className={classes.form} noValidate onSubmit={resetWithFirebase}>
              <TextField
                autoComplete="resetemail"
                autoFocus
                className={classes.customInput}
                error={!!values.error}
                FormHelperTextProps={{
                  classes: {
                    root: classes.customInputHelperText,
                  },
                }}
                fullWidth
                helperText={!!values.error && values.error}
                id="resetemail"
                InputProps={{
                  classes: {
                    error: classes.customInputError,
                    root: classes.customInputChild,
                  },
                  endAdornment: values.error ? (
                    <InputAdornment position="end">
                      <ErrorIcon color="secondary" fontSize="small" />
                    </InputAdornment>
                  ) : undefined,
                  inputProps: {
                    classes: {
                      root: classes.customInputInput,
                    },
                  },
                }}
                margin="dense"
                name="resetemail"
                onChange={handleInputChange}
                placeholder="E-mail"
                required
                value={values.resetemail}
                variant="outlined"
              />
              <Button
                className={classes.submit}
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
              >
                <MailOutlineIcon /> Enviar link de recuperação
              </Button>
              {values.isSaving && <LinearProgress />}
              <Grid className={classes.resetPassword} item>
                <Link
                  className={classes.resetPasswordLink}
                  onClick={() => {
                    setReset({ ...reset, isReseting: false });
                    setValues((prevValues) => ({ ...prevValues, error: `` }));
                  }}
                  variant="body2"
                >
                  Voltar à página de entrada
                </Link>
              </Grid>
            </form>
          </span>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: `top`,
            horizontal: `center`,
          }}
          className={classes.success}
          open={values.isReseted}
          onClose={() => {}}
          TransitionComponent={Fade}
          ContentProps={{
            'aria-describedby': `message-id`,
          }}
          message={
            <span id="message-id">
              Email enviado, verifique sua caixa de entrada.
              <a className={classes.colore} href="/">
                Voltar para Login
              </a>
            </span>
          }
        />
        {redirectToHome && (
          <h1>
            <Redirect to="/quality" />
          </h1>
        )}
        {reset.isReseted && (
          <h1>
            <Redirect to="/" />
          </h1>
        )}
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: `top`, horizontal: `right` }}
        autoHideDuration={1000}
        message="E-mail copiado"
        onClose={() => setSnackbarStatus(false)}
        open={snackbarStatus}
      />
    </Container>
  );
}

SignIn.propTypes = {
  dialog: PropTypes.objectOf(PropTypes.func).isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default withDialog()(withRouter(SignIn));
