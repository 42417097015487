import React from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';
import { links } from './root.helper';

const NavLink = styled(Link)`
  width: 280px;
  height: 80px;
  box-shadow: 0 3px 6px #00000020;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: start;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 30px;
  border-radius: 4px;
  padding: 0.5rem;
  text-decoration: none;
  color: #707070;
  &:hover {
    box-shadow: 0 6px 10px #00000030;
  }
`;
const Title = styled.h1`
  font-size: 20px;
  color: #707070;
  font-weight: 400;
  font-family: 'Helvetica Neue';
  text-decorator: none;
`;

const PageTitle = styled.h1`
  font-size: 32px;
  color: #707070;
  font-family: 'Helvetica Neue';
`;

const Container = styled.div`
  width: 960px;
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
  padding: 20px;
`;

const ModulesContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const NavLinkIcon = styled.div`
  font-size: 35px;
  color: #4cabaf;
  text-align: center;
  height: 50px;
  width: 50px;
  border-radius: 4px;
  border: 3px solid #4cabaf;
  margin-right: 8px;
`;
const NavLinkInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavLinkTitle = styled.h3`
  margin: 0;
`;

const NavLinkDesc = styled.p`
  margin: 0;
`;

const HubComponent = () => (
  <Container>
    <PageTitle>Plataforma de Medicina Diagnóstica</PageTitle>
    <Title>Acesse todas as soluções de forma fácil e rápida.</Title>
    <ModulesContainer>
      {links.map(link => {
        return (
          <NavLink key={link.href} className="primary-navigation-link" to={link.href}>
            <NavLinkIcon>{link.name.substring(0, 1)}</NavLinkIcon>
            <NavLinkInfo>
              <NavLinkTitle>{link.name}</NavLinkTitle>
              <NavLinkDesc>{link.desc}</NavLinkDesc>
            </NavLinkInfo>
          </NavLink>
        );
      })}
    </ModulesContainer>
  </Container>
);

export default HubComponent;
