import React from 'react';
import { Scoped } from 'kremling';
import styled from 'styled-components';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { DialogProvider } from 'muibox';
import { CssBaseline } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import styles from './root.krem.css';
import HubComponent from './HubComponent';
import PrivateRoute from './PrivateRoute';
import SignIn from './SignIn';
import ChangePassword from './ChangePassword';

const MainPage = styled.div`
  height: 100vh;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

const theme = createMuiTheme({
  palette: {
    common: {
      black: `#222`,
      white: `#fff`,
    },
    primary: {
      light: `#6fced3`,
      main: `#4ac3c9`,
      dark: `#2c1d49`,
      contrastText: `#fff`,
    },
    secondary: {
      light: `#ff4081`,
      main: `#ee0000`,
      dark: `#7c0000`,
      contrastText: `#fff`,
    },
    error: {
      light: `#e57373`,
      main: `#ee0000`,
      dark: `#7c0000`,
      contrastText: `#fff`,
    },
    warning: {
      light: `#ffb74d`,
      main: `#ff9800`,
      dark: `#f57c00`,
      contrastText: `rgba(0, 0, 0, 0.87)`,
    },
    info: {
      light: `#64b5f6`,
      main: `#2196f3`,
      dark: `#1976d2`,
      contrastText: `#fff`,
    },
    success: {
      light: `#81c784`,
      main: `#2dc376`,
      dark: `#388e3c`,
      contrastText: `#fff`,
    },
    grey: {
      50: `#fdfdfd`,
      100: `#fafafa`,
      200: `#f5f5f5`,
      300: `#e4e4e4`,
      400: `#767676`,
      500: `#9e9e9e`,
      600: `#757575`,
      700: `#616161`,
      800: `#424242`,
      900: `#212121`,
      A100: `#d5d5d5`,
      A200: `#aaaaaa`,
      A400: `#303030`,
      A700: `#616161`,
    },
  },
});

const Root = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DialogProvider>
        <Scoped css={styles}>
          <MainPage>
            <BrowserRouter>
              <Switch>
                <Route exact path="/">
                  <SignIn />
                </Route>
                <Route exact path="/changepassword">
                  <ChangePassword />
                </Route>
                <PrivateRoute exact path="/hub" title="Hub">
                  <HubComponent />
                </PrivateRoute>
                <Redirect from="/quality" to="/quality" />
                <Redirect from="*" to="/404" />
              </Switch>
            </BrowserRouter>
          </MainPage>
        </Scoped>
      </DialogProvider>
    </ThemeProvider>
  );
};

export default Root;
