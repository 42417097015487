import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Container,
  InputAdornment,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import firebase from './firebaseConfig';
import AuthLogo from './assets/auth-logo.svg';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: 50,
    height: 50,
  },
  changePasswordTypography: {
    color: theme.palette.primary.dark,
    font: `600 18px/25px Hind`,
    letterSpacing: -0.3,
    margin: `${theme.spacing(6.25)}px 0 ${theme.spacing(1.5)}px 0`,
    textAlign: `left`,
  },
  customInput: {
    borderRadius: theme.spacing(0.75),
    color: theme.palette.grey[400],
    font: `normal 14px/20px Hind`,
    letterSpacing: 0,
    minHeight: theme.spacing(2.875),
    textAlign: `left`,
    '& > div > fieldset': {
      border: `none`,
    },
  },
  customInputChild: {
    background: `#F5F5F5 0% 0% no-repeat padding-box`,
  },
  customInputError: {
    border: `2px solid ${theme.palette.secondary[theme.palette.type]}`,
    borderRadius: theme.spacing(0.75),
  },
  customInputHelperText: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(
      1,
    )}px`,
  },
  customInputInput: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.25)}px`,
  },
  customInputWrapperError: {
    background: `${theme.palette.secondary[theme.palette.type]}1a 0% 0% no-repeat padding-box`,
  },
  main: {
    alignContent: `center`,
    alignItems: `center`,
    display: `flex`,
    justifyContent: `center`,
  },
  paper: {
    alignContent: `flex-start`,
    alignItems: `flex-start`,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    maxWidth: 540,
    padding: theme.spacing(2),
  },
  form: {
    width: `100%`, // Fix IE 11 issue.
  },
  submit: {
    font: `600 14px/20px Hind`,
    letterSpacing: 0,
    lineHeight: `${theme.spacing(2.875)}px`,
    marginTop: theme.spacing(2),
    textAlign: `left`,
    textTransform: `none`,
    '& > span > svg': {
      marginRight: theme.spacing(1.25),
    },
  },
}));

function ChangePassword() {
  const [values, setValues] = useState({
    error: ``,
    isSaving: false,
    password1: ``,
    password2: ``,
  });
  const [redirectToHome, setRedirectToHome] = useState(false);
  const classes = useStyles();

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setValues({ ...values, isSaving: true });
    const user = await firebase.auth().currentUser;
    if (values.password1 !== values.password2) {
      setValues({ ...values, error: `As senhas informadas não conferem!` });
      return;
    }
    user
      .updatePassword(values.password1)
      .then(() => {
        setValues({
          error: ``,
          isSaving: false,
          password1: ``,
          password2: ``,
        });
        const db = firebase.firestore();
        db.collection(`users`).doc(user.uid).update({ forceChangePassword: false });
        setRedirectToHome(true);
      })
      .catch((error) => {
        const { message } = error;
        setValues({
          ...values,
          error: message,
          isSaving: false,
        });
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <Container className={classes.main} component="main">
      <Paper className={classes.paper}>
        <AuthLogo viewBox="7 15 151 29.70" />
        <Typography className={classes.changePasswordTypography}>
          Alteração de senha no primeiro acesso.
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleChangePassword}>
          <TextField
            autoFocus
            classes={{
              root: values.error ? classes.customInputWrapperError : undefined,
            }}
            className={classes.customInput}
            error={!!values.error}
            FormHelperTextProps={{
              classes: {
                root: classes.customInputHelperText,
              },
            }}
            fullWidth
            id="password1"
            InputProps={{
              classes: {
                error: classes.customInputError,
                root: classes.customInputChild,
              },
              endAdornment: values.error ? (
                <InputAdornment position="end">
                  <ErrorIcon color="secondary" fontSize="small" />
                </InputAdornment>
              ) : undefined,
              inputProps: {
                classes: {
                  root: classes.customInputInput,
                },
              },
            }}
            margin="dense"
            name="password1"
            onChange={handleInputChange}
            placeholder="Nova senha"
            required
            type="password"
            value={values.password1}
            variant="outlined"
          />
          <TextField
            autoComplete="current-password"
            classes={{
              root: values.error ? classes.customInputWrapperError : undefined,
            }}
            className={classes.customInput}
            error={!!values.error}
            FormHelperTextProps={{
              classes: {
                root: classes.customInputHelperText,
              },
            }}
            fullWidth
            helperText={!!values.error && values.error}
            id="password2"
            InputProps={{
              classes: {
                error: classes.customInputError,
                root: classes.customInputChild,
              },
              endAdornment: values.error ? (
                <InputAdornment position="end">
                  <ErrorIcon color="secondary" fontSize="small" />
                </InputAdornment>
              ) : undefined,
              inputProps: {
                classes: {
                  root: classes.customInputInput,
                },
              },
            }}
            margin="dense"
            name="password2"
            onChange={handleInputChange}
            placeholder="Confirmar a senha"
            required
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={values.isSaving}
          >
            Confirmar
          </Button>
          {values.isSaving && <LinearProgress />}
        </form>
        {redirectToHome && (
          <h1>
            <Redirect to="/quality" />
          </h1>
        )}
      </Paper>
    </Container>
  );
}

export default ChangePassword;
